.login {
  height: 100vh;
  // background-image: url('../../assets/img/orcastrike.jpg');
  // background-image: url('../../assets/img/red-bull-racing.jpg');
  // background-image: url('../../assets/img/red-bull-racing2.jpg');
  background-image: url('../../assets/img/red-bull-racing3.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-panel {
  background-color: rgba(19, 19, 19, 0.5);
  // display: flex;
  // flex-direction: column;
}
