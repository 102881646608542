.racetrack-container {
  width: 100%;
  box-sizing: border-box;
  font-family: 'Roboto';

  .body {
    border: 1px solid #bcd4e6; /* #464545; */
    border-radius: 4px;
    overflow: hidden;
  }

  .inner-body {
    position: relative;
    height: 36px;
    background-color: #bcd4e6;
    box-sizing: border-box;
    text-align: center;
    line-height: 200%;

    .timer {
      position: absolute;
      bottom: 0;
      height: 20px;
      z-index: 1;
      background-color: #ffea00; /*#fff700; #99badd; #f3e5ab*/
    }

    .loss {
      position: absolute;
      height: 32px;
      width: 100%;
      z-index: 2;
      background-color: #cd5c5c;
    }

    .profit {
      position: absolute;
      height: 32px;
      z-index: 3;
      background-color: #ace1af;
    }

    .slippage {
      position: absolute;
      height: 32px;
      z-index: 4;
      background-color: #fc8eac;
    }

    .start {
      position: absolute;
      margin: 2px;
      z-index: 7;
      opacity: 0.5;
    }

    .finish {
      position: absolute;
      margin: 2px;
      margin-left: -30px;
      padding-left: 100%;
      z-index: 6;
      opacity: 0.5;
    }

    .stop-loss {
      position: absolute;
      height: 32px;
      /*width: 0%;*/
      z-index: 8;
      border-right: 5px solid #c41e3a;
    }

    .racer {
      position: absolute;
      top: 4px;
      z-index: 10;

      img {
        cursor: pointer;
      }
    }
  }
}
